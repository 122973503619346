import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, tap } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseURL = environment.API_BASE_URL;
  //apiurl='https://localhost:44308/user/authenticate';
  apiurl = `${this.baseURL}/api/v1/authentication/sign-in`;
  apiSiginOut = `${this.baseURL}/api/v1/authentication/sign-out`;
  //  forgetapiurl='https://pscc-bkend.dev.nzt.atos.net/api/v1/authentication/forgot-password';
  forgetapiurl = `${this.baseURL}/api/v1/authentication/forgot-password`;

  // private baseURL = 'https://pscc-bkend.dev.nzt.atos.net/api/v1/authentication/forgot-password';

  constructor(private http: HttpClient) {}
  tokenresp: any;
  private _updatemenu = new Subject<void>();
  get updatemenu() {
    return this._updatemenu;
  }
  ProceedLogin(username: any) {
    return this.http.post(this.apiurl, username);
  }
  ProceedLogOut(username: any) {
    return this.http.post(this.apiSiginOut, username);
  }
  GenerateRefreshToken() {
    let input = {
      token: this.GetToken(),
      refreshToken: this.GetRefreshToken(),
    };
    return this.http.post(this.apiurl + 'refresh', input);
  }

  IsLoggedIn() {
    return localStorage.getItem('token') != null;
  }
  GetToken() {
    return localStorage.getItem('token') || '';
  }

  GetRefreshToken() {
    return localStorage.getItem('refreshtoken') || '';
  }
  SaveTokens(tokendata: any) {
    localStorage.setItem('token', tokendata.token);
    localStorage.setItem('refreshtoken', tokendata.refreshToken);
    sessionStorage.setItem('userRole', tokendata.userRole);
    localStorage.setItem('userRoleId', tokendata.userRoleId);
    localStorage.setItem('user', tokendata.user);
    localStorage.setItem('companyId', tokendata.companyId);
    localStorage.setItem('companyName', tokendata.companyName);
  }
  HaveAccess() {
    var loggintoken = localStorage.getItem('userRole') || '';
    //  var _extractedtoken=loggintoken.split('.')[1];
    //  var _atobdata=atob(_extractedtoken);
    //  var _finaldata=JSON.parse(_atobdata);
    console.log(loggintoken, 'hhh');
    if (loggintoken == '') {
      return true;
    } else {
      alert('you not having access');
      return false;
    }
  }
  getuserRole() {
    return sessionStorage.getItem('userRole');
  }
  getuserRoleId() {
    return localStorage.getItem('userRoleId');
  }
  getusertoken() {
    return localStorage.getItem('token');
  }
  getuser() {
    return localStorage.getItem('user');
  }
  getuserId() {
    return localStorage.getItem('userId');
  }
  getuseremailId() {
    return localStorage.getItem('email');
  }
  getcompanyId() {
    return localStorage.getItem('companyId');
  }
  getcompanyName() {
    return localStorage.getItem('companyName');
  }

  getSessionId() {
    return sessionStorage.getItem('sessionId');
  }

  getSessionIdFromToken() {
    let token = localStorage.getItem('token') || '';
    let sessionId = '';
    if (token !== '') {
      const payload = jwt_decode(token) as any;
      sessionId =
        payload[
          'http://schemas.microsoft.com/ws/2008/06/identity/claims/sessionId'
        ];
    }

    return sessionId;
  }

  checkSession() {
    let userId = localStorage.getItem('userId');
    let sessionId = sessionStorage.getItem('sessionId');
    return this.http.get(
      `${this.baseURL}/api/v1/authentication/checkSession/${userId}/${sessionId}`
    );
  }

  forgetPassword(username: any) {
    // return this.http.post(this.forgetapiurl,username);
    return this.http.post(
      `${this.baseURL}/api/v1/authentication/forgot-password`,
      username
    );
  }

  // forgetPassword(email: string): Observable<any> {
  //   return this.http.post(`${this.baseURL}/forgot-password`, { email });
  // }

  resetPassword(data: any) {
    // let params = new HttpParams()
    //   .set('newPassword', data)
    //   .set('userId', userId)
    //   .set('token', token)
    return this.http
      .post(`${this.baseURL}/api/v1/authentication/reset-password`, data)
      .pipe(
        tap(() => {
          // this._refreshNeed$.next();
        })
      );
  }

  //  forgetPassword(data:any){
  //   return this.http.post<any>(`https://pscc-bkend.dev.nzt.atos.net/api/v1/authentication/forgot-password`,{
  //     requestType : 'PASSWORD_RESET',
  //     email: data.email
  //   })
  //   // .pipe(
  //   //   catchError(err=>{
  //   //     return this._errService.handleError(err);
  //   //   })
  //   // )
  //  }

  // updatePassword(email:any){

  //   return this.http.get(`https://pscc-bkend.dev.nzt.atos.net/api/v1/users/updatepassword/${email}`)
  // }

  updatePassword(email: any) {
    return this.http.get(
      `${this.baseURL}/api/v1/users/updatepassword/` + email.email
    );
  }
}
