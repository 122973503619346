import { mergeMap as _observableMergeMap, catchError as _observableCatch, map } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');
@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
    }
    logIn(username: any, password: any):Observable<any> {
      let url_ = this.baseUrl + "/api/Authentication/SignIn";
      url_ = url_.replace(/[?&]$/, "");

      const content_ = JSON.stringify({ username: username, password: password });

      let options_: any = {
          body: content_,
          observe: "response",
          responseType: "text",
          headers: new HttpHeaders({
              "Content-Type": "application/json",
              "Accept": "application/json"
          })
      };   
      return this.http.request<any>("post", url_, options_);  

    //   return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
    //       return response_;
    //   })).pipe(_observableCatch((response_: any) => {
    //     return response_;
    //     //   if (response_ instanceof HttpResponseBase) {
    //     //       try {
    //     //           return this.processSignIn(<any>response_);
    //     //       } catch (e) {
    //     //           return <Observable<SignInResponse>><any>_observableThrow(e);
    //     //       }
    //     //   } else
    //     //       return <Observable<SignInResponse>><any>_observableThrow(response_);
    //   }));
  }
  IsLoggedIn(){
    return localStorage.getItem('token')!=null;
  }
  GetToken(){
   return localStorage.getItem('token')||'';
  }
  HaveAccess(){
    var loggintoken=localStorage.getItem('token')||'';
    var _extractedtoken=loggintoken.split('.')[1];
    var _atobdata=atob(_extractedtoken);
    var _finaldata=JSON.parse(_atobdata);
    if(_finaldata.role==''){
      return true
    }else{
      alert('you not having access');
      return false
    }
  }

  getroles(token: string):Observable<any> {
    let url_ = this.baseUrl + "/api/Roles/getRoles";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify({ token:token });

    let options_: any = {
        body: content_,
        observe: "response",
        responseType: "text",
        headers: new HttpHeaders({
            "Content-Type": "application/json",
            "Accept": "application/json"
        })
    };   
    return this.http.request<any>("get", url_, options_);  
}
}