import { Component } from '@angular/core';
import { AuthService } from './service/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'IT-Carbon';;
  interval:any
  constructor(private service: AuthService,private router: Router,){
  }
  ngOnInit(){
    this.interval = setInterval(() => {
      let sessionId = this.service.getSessionId();
      let sessionIdFromToken = this.service.getSessionIdFromToken();
      if(sessionId){
        if (sessionId != sessionIdFromToken) {
          sessionStorage.clear();
          this.router.navigate(['auth/login']);
  
        }
        else{
          this.service.checkSession().subscribe((res :any)=>{
            if(res.session=== 'CLOSED'){
              sessionStorage.clear();
              this.router.navigate(['auth/login']);
            }
          })
        }
      }
     }, 1000);
    }
}
